.bg-dark {
    background-color: #00347d !important;
    color: #fff !important;
}

.bg-dark.social__link--style1 svg,
.social__link--style1 i {
    color: #ffffff !important;
}

.bg-body-tertiary {
    background-color: #ffffff !important;
    backdrop-filter: blur(5px) !important;
}

.bg-body-tertiary.dark {
    background-color: #0000005c !important;
    backdrop-filter: blur(5px) !important;
}

a {
    text-decoration: none !important;
    color: black !important;
}

.trk-btn--primary {
    background-color: #2278ed !important;
    border: 1px solid #2278ed !important;
}

.trk-btn--primary.nav-link {
    color: #fff !important;
}

.trk-btn {
    padding: 0.8rem 1.35rem !important;
}

.text-purple {
    color: #6f42c1;
}

.text-teal {
    color: #20c997;
}

.step-1 {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 24px;
    min-width: 288px;
    background: linear-gradient(60deg, #185B64 0%, #114147 100%);
    color: #fff;
}

.account-step {
    padding-bottom: 20px;
}

.step-1 .step-number {
    color: #fff;
}

.step-1.dark {
    background: linear-gradient(60deg, #00d7f4 0%, #106c78 100%);
}

.rest-3-step {
    display: flex;
    align-items: stretch;
    gap: 64px;
    background-color: #FFFFFF;
    box-shadow: 0 32px 80px 0 #18333d1f;
    padding: 25px 20px;
    border-radius: 20px;
}

.rest-3-step.dark {
    background-color: #0c263a;
}

.text-justify {
    text-align: justify;
}

.feature.dark {
    background-color: #0d3c5f;
}

.features-item.dark {
    color: #ffffff !important;
    background-color: #008cff !important;
}

.features-item.dark.active {
    color: #ffffff !important;
    background-color: #00579d !important;
}

.step-number {
    background-color: rgb(121 165 180 / 38%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 12px;
    color: #000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 24px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.step-number.dark {
    background-color: rgb(0, 46, 61);
    color: #fff;
}

.list-style-disc {
    list-style: disc;
}

.footer__linklist-item a {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.6) !important;
}

.nav-link {
    font-weight: normal;
}

.nav-link.active {
    font-weight: 500;
    color: #2278ed !important;
}

.account-step.dark {
    background: lightblue;
}

.account-step:hover {
    border: 2px solid #007d8b;
}

.w-35 {
    width: 35px;
}

.h-35 {
    height: 35px;
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* or any other color */
    z-index: 9999;
}

.preloader img {
    width: 100px;
    /* Adjust the size as needed */
    height: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1370px;
    }
}

.hZUxNm {
    font-size: 16px;
    font-weight: 700;
}

.footer__linklist {
    padding-left: 0px;
}

.bg-dark .btn-outline-light,
.dark .btn-outline-light {
    color: #ffffff !important;
}

.bg-dark .btn-outline-light:hover,
.dark .btn-outline-light:hover {
    color: #000000 !important;
}

.page {
    padding: 85px 0px 0px;
}

.page h2,
.page h3,
.page p {
    color: #000000;
}

.page.dark h2,
.page.dark h3,
.page.dark h4,
.page.dark h5,
.page.dark p,
.page.dark ul,
.page.dark ol,
.page.dark address {
    color: #fff;
}

.page .blog-content a {
    color: #007bff !important;
}

.text-light {
    color: #FFFFFF !important;
}

.page ol {
    list-style: auto;
}

.page ul {
    list-style: disc;
}

.blog-image {
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin: 5px auto;
    max-width: 100%;
    max-height: calc(40vh + 180px);
    border-radius: 0.375rem;
}

.indent {
    margin-left: 1em;
}

.list-style-numbered {
    list-style: decimal inside;
}

.bg_welcome {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.main-coin {
    cursor: pointer;
}

.input-group-text {
    min-width: 90px;
}

.welcome_content {
    width: 50%;
    position: relative;
    padding: 50px 100px;
    background-color: hsla(0, 0%, 100%, .2);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    margin: 0px auto;
    border: 1px solid rgb(185 187 203 /1);
    border-radius: 15px;
    text-align: center;
}

.coin-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.from-coin,
.to-coin {
    display: flex;
    align-items: center;
}

#trade-tabs.nav-tabs .nav-link.active {
    background-color: #a6daf5 !important;
    border-color: #a9e2ff !important;
}

#trade-tabs.nav-tabs .nav-link {
    padding: 10px;
}

#trade-tabs,
#settings-tabs {
    border-bottom: none !important;
    list-style: none;
}

.trade-tabs-tab-buy {
    padding: 15px 0px;
}

.cMWngM:hover,
.gRfwAR:hover {
    background-color: #6a8eff !important;
}

.cMWngM:not(:last-of-type),
.gRfwAR:not(:last-of-type) {
    border-bottom-color: rgba(255, 255, 255, 0.12) !important;
}

#userKYC {
    border-bottom: none !important;
    list-style: none;
}

#userKYC.nav-tabs .nav-link.active {
    background-color: #fafcfd !important;
    border-color: #2200ff5c !important;
}

#userKYC .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    background-color: #ebf8ff !important;
}

#userKYC.nav-tabs .nav-link {
    padding: 40px;
}

.coin-symbol {
    height: 20px;
    width: 20px;
}

.coin-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.from-coin {
    margin-right: 10px;
}

.to-coin {
    margin-left: 10px;
}

.coinList {
    position: absolute;
    max-width: 495px;
    margin-top: -20px;
    background: #fff;
    max-height: 200px;
    width: 100%;
    border: 1px solid #e0e0e094;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 999999;
}

.coinList-sm {
    position: absolute;
    max-width: 295px;
    margin-top: -20px;
    background: #fff;
    max-height: 200px;
    width: 100%;
    border: 1px solid #e0e0e094;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 999999;
}

.close-icon {
    position: absolute;
    top: 108px;
    right: 96px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
}

.form-check-input:checked {
    background-color: #00255b !important;
    border-color: #023888 !important;
}

#faqAccordion ol {
    list-style: decimal;
}

.feature-img {
    max-width: 400px;
    height: auto;
}

.features-item {
    color: #000 !important;
    background-color: #ffffff !important;
    padding: 15px 20px;
    border: 1px solid #d6d6d661;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
}

.features-item.active {
    color: #000 !important;
    background-color: #ffffff !important;
    padding: 15px 20px;
    border: 1px solid #d6d6d661;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.icon-circle {
    background-color: #6999ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-circle svg {
    color: #ffffff;
}

.action-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
}

.card.dark {
    background: #00000099;
}

.rdt_TableHeadRow {
    background-color: #fff;
}

.dark .rdt_TableHeadRow {
    background-color: #416dcd;
}

.dark .rdt_Table,
.dark .rdt_TableBody,
.dark .rdt_TableRow {
    background-color: transparent;
}

.dark .rdt_TableRow,
.dark .rdt_TableHead {
    color: #fff;
}

.dark .rdt_Pagination {
    color: rgb(255 255 255 / 54%);
    background-color: #416dcd;
}

.dark #pagination-first-page,
.dark #pagination-previous-page,
.dark #pagination-next-page,
.dark #pagination-last-page {
    color: rgb(255 255 255);
    fill: rgb(255 255 255 / 49%);
}

.dark .modal-content {
    background-color: #1c1d21;
}

.dark .btn-close {
    filter: brightness(0) invert(1);
}

.cursor-pointer {
    cursor: pointer;
}

.step-card {
    background-color: #f9f9f9;
    border-color: #ddd;
    transition: transform 0.3s ease;
}

.deposit-step.dark .step-card {
    background-color: #00000063;
    border-color: #ffffff !important;
    box-shadow: 0px 0px 3px 1px #ffffff52;
    transition: transform 0.3s ease;

}

.deposit-step .step-card strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
}

.deposit-step .step-card p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #87909f;
}

.step-card:hover {
    transform: translateY(-5px);
}

.deposit-step .step-number {
    width: 30px;
    height: 30px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    font-size: 15px;
    margin-bottom: 5px;
}

.dark .placeholder {
    background-color: #6c757d;
    /* Darker placeholder color */
}

.dark .placeholder-glow .placeholder {
    animation: placeholder-shimmer 1.5s infinite linear;
}

.dark .css-13cymwt-control {
    background-color: hsl(0deg 0% 100% / 0%);
}

.dark .css-1dimb5e-singleValue {
    color: #ffffff;
}

/* Animation for the placeholder */
@keyframes placeholder-shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

/* For the placeholder in dark mode, adjust glow colors */
.dark .placeholder-glow {
    background: linear-gradient(90deg, #333 25%, #555 50%, #333 75%);
    background-size: 200% 100%;
}

@media (max-width: 600px) {
    .bg_welcome {
        padding: 35px;
    }

    .welcome_content {
        width: 100%;
        padding: 10px 10px;
    }

    .lightdark-switch span {
        position: absolute;
        top: 27px;
        right: 15px;
        left: auto;
        bottom: auto;
    }

    .dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .icon-circle {
        width: 45px;
        height: 45px;
    }
}